const formatGroupDisplayName = (string: string | undefined | null) => {
  if (!string) return undefined;
  const underscoredRemoved = string?.replace(/(_|-)/g, " ");
  return underscoredRemoved
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
};

export default formatGroupDisplayName;
