import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Group } from "@microsoft/microsoft-graph-types-beta";

import { getAllGroups } from "../services/userManagementService";
import filterRoles from "../utils/filterRoles";
import filterSports from "../utils/filterSports";

// this uses the same method as useGroupMembers but is separated for convenience
const useAllGroups = () => {
  const queryClient = useQueryClient();

  const allGroupsQuery = useQuery({
    queryKey: ["all-groups"],
    queryFn: () => getAllGroups(),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const allGroups = queryClient.getQueryData<any>(["all-groups"])
    ?.data as Group[];

  const roles = filterRoles(allGroups);

  const sports = filterSports(allGroups);

  return {
    allGroupsQuery,
    allGroups,
    roles,
    sports,
  };
};

export default useAllGroups;
