import { components } from "../types/projects";

const filterRoles = (groups: components["schemas"]["Group"][]) => {
  return groups?.filter(
    (group: components["schemas"]["Group"]) =>
      group.id === process.env.GATSBY_PPP_PRACTITIONER_GROUP_ID ||
      group.id === process.env.GATSBY_PPP_SPORT_LEAD_GROUP_ID ||
      group.id === process.env.GATSBY_PPP_EMPLOYEE_GROUP_ID ||
      group.id === process.env.GATSBY_WIPS_GROUP_ID
  ) as components["schemas"]["Group"][];
};

export default filterRoles;
