import { components } from "../types/projects";

const filterSports = (groups: components["schemas"]["Group"][]) => {
  const allRoleIds = [
    process.env.GATSBY_PPP_PRACTITIONER_GROUP_ID,
    process.env.GATSBY_PPP_SPORT_LEAD_GROUP_ID,
    process.env.GATSBY_PPP_EMPLOYEE_GROUP_ID,
    process.env.GATSBY_WIPS_GROUP_ID,
    process.env.GATSBY_PPP_USER_MANAGERS_GROUP_ID,
    process.env.GATSBY_PPP_USERS_GROUP_ID,
    process.env.GATSBY_SYSTEM_GROUP_ID,
  ];
  return groups?.filter(
    (group: components["schemas"]["Group"]) =>
      !Object.values(allRoleIds).includes(group.id as any)
  ) as components["schemas"]["Group"][];
};

export default filterSports;
