import { Roles } from "../types/roles.enum";
import formatGroupDisplayName from "./formatGroupDisplayName";

const getGroupDisplayName = (
  id: string | undefined,
  displayName: string | undefined | null
) => {
  switch (id) {
    case process.env.GATSBY_PPP_PRACTITIONER_GROUP_ID:
      return Roles.PRACTITIONER;
    case process.env.GATSBY_PPP_SPORT_LEAD_GROUP_ID:
      return Roles.SPORT_LEAD;
    case process.env.GATSBY_PPP_EMPLOYEE_GROUP_ID:
      return Roles.SPORT_WALES;
    case process.env.GATSBY_WIPS_GROUP_ID:
      return Roles.WIPS;
    default:
      return formatGroupDisplayName(displayName);
      break;
  }
};

export default getGroupDisplayName;
